import '@angular/compiler';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PageAdminEformComponent } from './page.admin.eform/page.admin.eform.component';
import { PortalWidgetCardComponent } from './portal.widget.card/portal.widget.card.component';
import { LayoutLayoutAsideFullComponent } from './layout.layout.aside.full/layout.layout.aside.full.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalEformHrVacationAfterComponent } from './portal.eform.hr.vacation.after/portal.eform.hr.vacation.after.component';
import { PortalEformHrCommuteLogComponent } from './portal.eform.hr.commute.log/portal.eform.hr.commute.log.component';
import { ComponentOnupdateComponent } from './component.onupdate/component.onupdate.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PageAdiminUserComponent } from './page.adimin.user/page.adimin.user.component';
import { PageMydeskMemoComponent } from './page.mydesk.memo/page.mydesk.memo.component';
import { PageMydeskMypageComponent } from './page.mydesk.mypage/page.mydesk.mypage.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalWidgetChartServerGpuComponent } from './portal.widget.chart.server.gpu/portal.widget.chart.server.gpu.component';
import { PageAdminEformViewerComponent } from './page.admin.eform.viewer/page.admin.eform.viewer.component';
import { PortalEformHrVacationSickComponent } from './portal.eform.hr.vacation.sick/portal.eform.hr.vacation.sick.component';
import { PageContactComponent } from './page.contact/page.contact.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PageEformMainComponent } from './page.eform.main/page.eform.main.component';
import { PageEformDocsComponent } from './page.eform.docs/page.eform.docs.component';
import { PageAdminDashboardComponent } from './page.admin.dashboard/page.admin.dashboard.component';
import { PortalWidgetEformApprovalComponent } from './portal.widget.eform.approval/portal.widget.eform.approval.component';
import { PageEformApprovalItemComponent } from './page.eform.approval.item/page.eform.approval.item.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalEformHrVacationPublicComponent } from './portal.eform.hr.vacation.public/portal.eform.hr.vacation.public.component';
import { ComponentQuickmenuComponent } from './component.quickmenu/component.quickmenu.component';
import { PortalEformHrBusinesstripInternalComponent } from './portal.eform.hr.businesstrip.internal/portal.eform.hr.businesstrip.internal.component';
import { PortalUtilMemoComponent } from './portal.util.memo/portal.util.memo.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { ComponentEformViewerComponent } from './component.eform.viewer/component.eform.viewer.component';
import { PageAdminUserComponent } from './page.admin.user/page.admin.user.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalEformHrVacationUnpaidComponent } from './portal.eform.hr.vacation.unpaid/portal.eform.hr.vacation.unpaid.component';
import { PortalEformHrEventCancelComponent } from './portal.eform.hr.event.cancel/portal.eform.hr.event.cancel.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { PageEformDocsItemComponent } from './page.eform.docs.item/page.eform.docs.item.component';
import { PortalWidgetChartServerEnvComponent } from './portal.widget.chart.server.env/portal.widget.chart.server.env.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageEformApprovalComponent } from './page.eform.approval/page.eform.approval.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalWidgetEformDocsComponent } from './portal.widget.eform.docs/portal.widget.eform.docs.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { PageMydeskCalendarComponent } from './page.mydesk.calendar/page.mydesk.calendar.component';
import { PortalEformRendererComponent } from './portal.eform.renderer/portal.eform.renderer.component';
import { PortalEformHrVacationAnnualComponent } from './portal.eform.hr.vacation.annual/portal.eform.hr.vacation.annual.component';
import { LayoutAsideComponent } from './layout.aside/layout.aside.component';
import { PageMydeskWorklogComponent } from './page.mydesk.worklog/page.mydesk.worklog.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

@NgModule({
    declarations: [
        AppComponent,
        ComponentNavUserComponent,
        PageAdminEformComponent,
        PortalWidgetCardComponent,
        LayoutLayoutAsideFullComponent,
        PortalSeasonLoadingHexaComponent,
        PortalEformHrVacationAfterComponent,
        PortalEformHrCommuteLogComponent,
        ComponentOnupdateComponent,
        ComponentNavAdminComponent,
        PortalSeasonPagenationComponent,
        PageAdiminUserComponent,
        PageMydeskMemoComponent,
        PageMydeskMypageComponent,
        LayoutEmptyComponent,
        PortalWidgetChartServerGpuComponent,
        PageAdminEformViewerComponent,
        PortalEformHrVacationSickComponent,
        PageContactComponent,
        PageAuthenticateComponent,
        PageEformMainComponent,
        PageEformDocsComponent,
        PageAdminDashboardComponent,
        PortalWidgetEformApprovalComponent,
        PageEformApprovalItemComponent,
        PortalSeasonStatusbarComponent,
        PortalSeasonAlertComponent,
        PortalEformHrVacationPublicComponent,
        ComponentQuickmenuComponent,
        PortalEformHrBusinesstripInternalComponent,
        PortalUtilMemoComponent,
        PortalSeasonUiDropdownComponent,
        ComponentEformViewerComponent,
        PageAdminUserComponent,
        PortalSeasonTabComponent,
        PortalEformHrVacationUnpaidComponent,
        PortalEformHrEventCancelComponent,
        PageDashboardComponent,
        PageEformDocsItemComponent,
        PortalWidgetChartServerEnvComponent,
        PortalSeasonLoadingSeasonComponent,
        PageEformApprovalComponent,
        PortalSeasonViewerTreeComponent,
        PortalWidgetEformDocsComponent,
        PortalSeasonLoadingDefaultComponent,
        PageMydeskCalendarComponent,
        PortalEformRendererComponent,
        PortalEformHrVacationAnnualComponent,
        LayoutAsideComponent,
        PageMydeskWorklogComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
        
    ],
    providers: [
        {
            provide: COMPOSITION_BUFFER_MODE,
            useValue: false
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }