import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.eform.hr.commute.log');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-portal-eform-hr-commute-log',
template: templateSource || '',
    styles: [``],
})
export class PortalEformHrCommuteLogComponent implements OnInit {
    @Input() doc: any = {};
    @Input() data: any = {};

    public rdata: any = { stat: {}, users: [] };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        if (!this.data.log_type) this.data.log_type = 'time';
        if (!this.data.timestamp) this.data.timestamp = moment().format("YYYY-MM-DD");
        if (!this.data.checker) this.data.checker = '';

        await this.loadUsers();
        await this.service.render();
    }

    public async loadUsers() {
        let { code, data } = await wiz.call("data", { doc_id: this.doc.id });
        this.rdata.users = data.users;
        this.rdata.stat = data.stat;
    }
}

export default PortalEformHrCommuteLogComponent;